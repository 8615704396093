import React, { useContext } from 'react';
import { Link } from 'gatsby';
import {
  Header as ShellHeader,
  HeaderMenuButton,
  SkipToContent,
  HeaderGlobalBar,
} from 'carbon-components-react/lib/components/UIShell';
import cx from 'classnames';
import GlobalSearch from 'gatsby-theme-carbon/src/components/GlobalSearch';
import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import useMetadata from 'gatsby-theme-carbon/src/util/hooks/useMetadata';
import {
  header,
  skipToContent,
  headerName,
  collapsed,
  headerButton,
} from './Header.module.scss';

import logo from '../../images/logo-tunggal.svg';

const CustomHeader = () => {
  const { leftNavIsOpen, toggleNavState, searchIsOpen } = useContext(
    NavContext,
  );
  const { isSearchEnabled } = useMetadata();

  return (
    <>
      <ShellHeader aria-label="Header" className={header}>
        <SkipToContent className={skipToContent} />
        <HeaderMenuButton
          className={cx('bx--header__action--menu', headerButton)}
          aria-label="Open menu"
          onClick={() => {
            toggleNavState('leftNavIsOpen');
          }}
          isActive={leftNavIsOpen}
        />
        <Link
          className={cx(headerName, {
            [collapsed]: searchIsOpen,
          })}
          to="/"
        >
          <img src={logo} style={{ marginRight: '8px' }} alt="Tunggal Logo" />
          <span>Tunggal</span>&nbsp;Design System
        </Link>
        <HeaderGlobalBar className={searchIsOpen ? 'search-open' : ''}>
          {isSearchEnabled && (
            <GlobalSearch className={cx('foobaar', headerButton)} />
          )}
        </HeaderGlobalBar>
      </ShellHeader>
    </>
  );
};

export default CustomHeader;
