import React from 'react';
import {
  HomepageBanner,
  HomepageCallout,
  PageDescription,
} from 'gatsby-theme-carbon';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import { calloutLink } from './Homepage.module.scss';

const FirstLeftText = () => <p>What is it?</p>;

const Description = () => (
  <PageDescription>
    <p>
      The design language that unites differences into one source of truth.
      Helping us to build an efficiency enterprise app experience
    </p>
  </PageDescription>
);

const SecondLeftText = () => <p>Principles</p>;

const SecondRightText = () => (
  <p>
    You can also not use these components at all by not providing the callout
    props to the template or writing your own template.
    <a className={calloutLink} href="#">
      Lern More →
    </a>
  </p>
);

const BannerText = () => (
  <fragment>
    <h1 className="headingBanner">Design, Develop and Make it Happend</h1>
    <Description />
  </fragment>
);

const customProps = {
  Banner: (
    <div id="BannerHero">
      <HomepageBanner renderText={BannerText} />
    </div>
  ),
  FirstCallout: <></>,
  SecondCallout: <></>,
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;
