import React from 'react';
import Switcher from 'gatsby-theme-carbon/src/components/Switcher';
import {
  divider,
  link,
  linkDisabled,
} from 'gatsby-theme-carbon/src/components/Switcher/Switcher.module.scss';

const SwitcherDivider = props => (
  <li className={divider}>
    <span {...props} />
  </li>
);

export const SwitcherLink = ({
  disabled,
  children,
  href: hrefProp,
  ...rest
}) => {
  const href = disabled || !hrefProp ? undefined : hrefProp;
  const className = disabled ? linkDisabled : link;

  return (
    <li>
      <a
        aria-disabled={disabled}
        role="button"
        className={className}
        href={href}
        {...rest}
      >
        {children}
      </a>
    </li>
  );
};

const CustomSwitcherChildren = () => (
  <>
    <SwitcherLink href="https://github.com">Link</SwitcherLink>
    <SwitcherLink href="https://github.com">Link</SwitcherLink>
    <SwitcherDivider>Divider</SwitcherDivider>
    <SwitcherLink href="https://github.com">Link</SwitcherLink>
    <SwitcherLink href="https://github.com">Link</SwitcherLink>
  </>
);

const CustomSwitcher = props => (
  <Switcher {...props}>
    <CustomSwitcherChildren />
  </Switcher>
);

export default CustomSwitcher;
