import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';
import timestamp from 'raw-loader!../../../build-timestamp';

const currentYear = new Date().getFullYear();
const lastUpdated = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format(new Date(Number(timestamp)));

const BhinnekaLogo = () => (
  <svg
    className="logo"
    width="199"
    height="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none">
      <path
        fill="#ED1C24"
        d="M0 36l4.65-25h8.686c3.49 0 6.142.579 7.95 1.735C23.096 13.892 24 15.586 24 17.82c0 1.548-.463 2.782-1.386 3.699-.924.917-2.264 1.467-4.02 1.65 1.516.228 2.708.796 3.576 1.706.867.91 1.3 2.041 1.3 3.395 0 2.39-.974 4.274-2.921 5.656C18.601 35.308 15.927 36 12.525 36H0zm10-6h.672c1.612 0 2.737-.167 3.373-.5.636-.333.955-.9.955-1.696 0-.619-.222-1.075-.664-1.366-.443-.292-1.142-.438-2.1-.438h-1.492L10 30zm162.944 4.245L185.451 11h10.19L199 36h-9.167l-.176-3.322h-6.195L182.055 36h-18.179l-3.805-10.946L158.041 36H149l4.606-25h9.058l-1.893 10.144L167.441 11H178l-9.432 12.62 4.376 10.625zM25 36l4.662-25h9.166l-1.588 8.637h4.971L43.816 11H53l-4.696 25h-9.166l1.709-8.986h-4.989L34.15 36H25zm28 0l4.689-25H67l-4.673 25H53zm14 0l4.664-25h9.274l2.902 7.94c.047.126.115.32.207.582.646 1.73 1.094 3.227 1.348 4.49L87.829 11H97l-4.68 25h-9.258l-4.68-12.91c-.047 1.173-.305 3.074-.778 5.705l-.034.204L76.275 36H67zm30 0l4.664-25h9.274l2.902 7.94c.046.126.116.32.209.582.643 1.73 1.092 3.227 1.345 4.49L117.83 11H127l-4.68 25h-9.257l-4.681-12.91c-.047 1.173-.305 3.074-.777 5.705l-.035.204L106.276 36H97zm30 0l4.662-25H149l-1.244 6.783h-8.237l-.467 2.413h7.756l-1.175 6.328h-7.737l-.5 2.693h8.98L145.113 36H127zm59-9h3l-.095-2.767a21.549 21.549 0 0 1-.032-.651 64.06 64.06 0 0 1-.08-2.785c0-.47.002-.831.008-1.083.005-.254.013-.492.024-.714a59.8 59.8 0 0 1-.593 2 47.55 47.55 0 0 1-.58 1.717L186 27zM12 20h.467c1.219 0 2.113-.129 2.68-.387.569-.258.853-.666.853-1.226 0-.43-.189-.768-.566-1.015-.377-.248-.9-.372-1.57-.372h-1.237L12 20z"
      />
      <path fill="#FFCA04" d="M59.009 2.67L81 0 58 8z" />
    </g>
  </svg>
);

const Content = () => (
  <>
    <p>
      Last updated {lastUpdated}
      <br />
      Copyright &copy; {currentYear} Bhinneka Design Team
    </p>
  </>
);

const links = {
  firstCol: [{ href: 'https://github.com', linkText: 'Shadowed link' }],
  secondCol: [{ href: 'https://github.com', linkText: 'Shadowed link' }],
};

const CustomFooter = () => (
  <Footer links={links} Content={Content} Logo={BhinnekaLogo} />
);

export default CustomFooter;
