// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-icons-js": () => import("./../src/pages/icons.js" /* webpackChunkName: "component---src-pages-icons-js" */),
  "component---src-pages-components-anchor-links-mdx": () => import("./../src/pages/components/AnchorLinks.mdx" /* webpackChunkName: "component---src-pages-components-anchor-links-mdx" */),
  "component---src-pages-components-alert-mdx": () => import("./../src/pages/components/Alert.mdx" /* webpackChunkName: "component---src-pages-components-alert-mdx" */),
  "component---src-pages-components-article-card-mdx": () => import("./../src/pages/components/ArticleCard.mdx" /* webpackChunkName: "component---src-pages-components-article-card-mdx" */),
  "component---src-pages-components-accordion-mdx": () => import("./../src/pages/components/Accordion.mdx" /* webpackChunkName: "component---src-pages-components-accordion-mdx" */),
  "component---src-pages-components-aside-mdx": () => import("./../src/pages/components/Aside.mdx" /* webpackChunkName: "component---src-pages-components-aside-mdx" */),
  "component---src-pages-components-badge-mdx": () => import("./../src/pages/components/Badge.mdx" /* webpackChunkName: "component---src-pages-components-badge-mdx" */),
  "component---src-pages-components-button-mdx": () => import("./../src/pages/components/Button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-avatar-mdx": () => import("./../src/pages/components/Avatar.mdx" /* webpackChunkName: "component---src-pages-components-avatar-mdx" */),
  "component---src-pages-components-breadcrumb-mdx": () => import("./../src/pages/components/Breadcrumb.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-mdx" */),
  "component---src-pages-components-button-navigation-mdx": () => import("./../src/pages/components/ButtonNavigation.mdx" /* webpackChunkName: "component---src-pages-components-button-navigation-mdx" */),
  "component---src-pages-components-caption-mdx": () => import("./../src/pages/components/Caption.mdx" /* webpackChunkName: "component---src-pages-components-caption-mdx" */),
  "component---src-pages-components-chat-mdx": () => import("./../src/pages/components/Chat.mdx" /* webpackChunkName: "component---src-pages-components-chat-mdx" */),
  "component---src-pages-components-code-blocks-mdx": () => import("./../src/pages/components/code-blocks.mdx" /* webpackChunkName: "component---src-pages-components-code-blocks-mdx" */),
  "component---src-pages-components-container-mdx": () => import("./../src/pages/components/Container.mdx" /* webpackChunkName: "component---src-pages-components-container-mdx" */),
  "component---src-pages-components-divider-mdx": () => import("./../src/pages/components/Divider.mdx" /* webpackChunkName: "component---src-pages-components-divider-mdx" */),
  "component---src-pages-components-feature-card-mdx": () => import("./../src/pages/components/FeatureCard.mdx" /* webpackChunkName: "component---src-pages-components-feature-card-mdx" */),
  "component---src-pages-components-do-dont-example-mdx": () => import("./../src/pages/components/DoDontExample.mdx" /* webpackChunkName: "component---src-pages-components-do-dont-example-mdx" */),
  "component---src-pages-components-filter-switcher-mdx": () => import("./../src/pages/components/FilterSwitcher.mdx" /* webpackChunkName: "component---src-pages-components-filter-switcher-mdx" */),
  "component---src-pages-components-footer-mdx": () => import("./../src/pages/components/Footer.mdx" /* webpackChunkName: "component---src-pages-components-footer-mdx" */),
  "component---src-pages-components-form-mdx": () => import("./../src/pages/components/Form.mdx" /* webpackChunkName: "component---src-pages-components-form-mdx" */),
  "component---src-pages-components-grid-mdx": () => import("./../src/pages/components/Grid.mdx" /* webpackChunkName: "component---src-pages-components-grid-mdx" */),
  "component---src-pages-components-header-mdx": () => import("./../src/pages/components/Header.mdx" /* webpackChunkName: "component---src-pages-components-header-mdx" */),
  "component---src-pages-components-image-card-mdx": () => import("./../src/pages/components/ImageCard.mdx" /* webpackChunkName: "component---src-pages-components-image-card-mdx" */),
  "component---src-pages-components-image-component-mdx": () => import("./../src/pages/components/ImageComponent.mdx" /* webpackChunkName: "component---src-pages-components-image-component-mdx" */),
  "component---src-pages-components-input-mdx": () => import("./../src/pages/components/Input.mdx" /* webpackChunkName: "component---src-pages-components-input-mdx" */),
  "component---src-pages-components-markdown-mdx": () => import("./../src/pages/components/markdown.mdx" /* webpackChunkName: "component---src-pages-components-markdown-mdx" */),
  "component---src-pages-components-menu-mdx": () => import("./../src/pages/components/Menu.mdx" /* webpackChunkName: "component---src-pages-components-menu-mdx" */),
  "component---src-pages-components-modal-mdx": () => import("./../src/pages/components/Modal.mdx" /* webpackChunkName: "component---src-pages-components-modal-mdx" */),
  "component---src-pages-components-navigation-mdx": () => import("./../src/pages/components/Navigation.mdx" /* webpackChunkName: "component---src-pages-components-navigation-mdx" */),
  "component---src-pages-components-overview-mdx": () => import("./../src/pages/components/overview.mdx" /* webpackChunkName: "component---src-pages-components-overview-mdx" */),
  "component---src-pages-components-page-description-mdx": () => import("./../src/pages/components/PageDescription.mdx" /* webpackChunkName: "component---src-pages-components-page-description-mdx" */),
  "component---src-pages-components-pagination-mdx": () => import("./../src/pages/components/Pagination.mdx" /* webpackChunkName: "component---src-pages-components-pagination-mdx" */),
  "component---src-pages-components-pop-over-mdx": () => import("./../src/pages/components/PopOver.mdx" /* webpackChunkName: "component---src-pages-components-pop-over-mdx" */),
  "component---src-pages-components-resource-card-mdx": () => import("./../src/pages/components/ResourceCard.mdx" /* webpackChunkName: "component---src-pages-components-resource-card-mdx" */),
  "component---src-pages-components-progress-indicator-mdx": () => import("./../src/pages/components/ProgressIndicator.mdx" /* webpackChunkName: "component---src-pages-components-progress-indicator-mdx" */),
  "component---src-pages-components-sheet-mdx": () => import("./../src/pages/components/Sheet.mdx" /* webpackChunkName: "component---src-pages-components-sheet-mdx" */),
  "component---src-pages-components-slider-mdx": () => import("./../src/pages/components/Slider.mdx" /* webpackChunkName: "component---src-pages-components-slider-mdx" */),
  "component---src-pages-components-step-wizard-mdx": () => import("./../src/pages/components/StepWizard.mdx" /* webpackChunkName: "component---src-pages-components-step-wizard-mdx" */),
  "component---src-pages-components-snack-bar-mdx": () => import("./../src/pages/components/SnackBar.mdx" /* webpackChunkName: "component---src-pages-components-snack-bar-mdx" */),
  "component---src-pages-components-table-mdx": () => import("./../src/pages/components/Table.mdx" /* webpackChunkName: "component---src-pages-components-table-mdx" */),
  "component---src-pages-components-tabs-mdx": () => import("./../src/pages/components/Tabs.mdx" /* webpackChunkName: "component---src-pages-components-tabs-mdx" */),
  "component---src-pages-components-tooltip-mdx": () => import("./../src/pages/components/Tooltip.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-mdx" */),
  "component---src-pages-components-video-mdx": () => import("./../src/pages/components/Video.mdx" /* webpackChunkName: "component---src-pages-components-video-mdx" */),
  "component---src-pages-contributions-mdx": () => import("./../src/pages/contributions.mdx" /* webpackChunkName: "component---src-pages-contributions-mdx" */),
  "component---src-pages-foundation-color-android-mdx": () => import("./../src/pages/foundation/color/android.mdx" /* webpackChunkName: "component---src-pages-foundation-color-android-mdx" */),
  "component---src-pages-demo-index-mdx": () => import("./../src/pages/demo/index.mdx" /* webpackChunkName: "component---src-pages-demo-index-mdx" */),
  "component---src-pages-foundation-color-design-mdx": () => import("./../src/pages/foundation/color/design.mdx" /* webpackChunkName: "component---src-pages-foundation-color-design-mdx" */),
  "component---src-pages-foundation-color-ios-mdx": () => import("./../src/pages/foundation/color/ios.mdx" /* webpackChunkName: "component---src-pages-foundation-color-ios-mdx" */),
  "component---src-pages-foundation-color-web-mdx": () => import("./../src/pages/foundation/color/web.mdx" /* webpackChunkName: "component---src-pages-foundation-color-web-mdx" */),
  "component---src-pages-foundation-elevation-android-mdx": () => import("./../src/pages/foundation/elevation/android.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-android-mdx" */),
  "component---src-pages-foundation-typography-android-mdx": () => import("./../src/pages/foundation/typography/android.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-android-mdx" */),
  "component---src-pages-foundation-elevation-ios-mdx": () => import("./../src/pages/foundation/elevation/ios.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-ios-mdx" */),
  "component---src-pages-foundation-elevation-design-mdx": () => import("./../src/pages/foundation/elevation/design.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-design-mdx" */),
  "component---src-pages-foundation-elevation-web-mdx": () => import("./../src/pages/foundation/elevation/web.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-web-mdx" */),
  "component---src-pages-foundation-typography-design-mdx": () => import("./../src/pages/foundation/typography/design.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-design-mdx" */),
  "component---src-pages-foundation-typography-ios-mdx": () => import("./../src/pages/foundation/typography/ios.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-ios-mdx" */),
  "component---src-pages-foundation-typography-web-mdx": () => import("./../src/pages/foundation/typography/web.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-web-mdx" */),
  "component---src-pages-gallery-index-mdx": () => import("./../src/pages/gallery/index.mdx" /* webpackChunkName: "component---src-pages-gallery-index-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-guides-navigation-sidebar-mdx": () => import("./../src/pages/guides/navigation/sidebar.mdx" /* webpackChunkName: "component---src-pages-guides-navigation-sidebar-mdx" */),
  "component---src-pages-guides-navigation-tabs-mdx": () => import("./../src/pages/guides/navigation/tabs.mdx" /* webpackChunkName: "component---src-pages-guides-navigation-tabs-mdx" */),
  "component---src-pages-guides-configuration-mdx": () => import("./../src/pages/guides/configuration.mdx" /* webpackChunkName: "component---src-pages-guides-configuration-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-guides-styling-mdx": () => import("./../src/pages/guides/styling.mdx" /* webpackChunkName: "component---src-pages-guides-styling-mdx" */),
  "component---src-pages-guides-shadowing-mdx": () => import("./../src/pages/guides/shadowing.mdx" /* webpackChunkName: "component---src-pages-guides-shadowing-mdx" */),
  "component---src-pages-labs-mdx": () => import("./../src/pages/labs.mdx" /* webpackChunkName: "component---src-pages-labs-mdx" */),
  "component---src-pages-layout-grid-mdx": () => import("./../src/pages/layout/Grid.mdx" /* webpackChunkName: "component---src-pages-layout-grid-mdx" */),
  "component---src-pages-overview-index-mdx": () => import("./../src/pages/overview/index.mdx" /* webpackChunkName: "component---src-pages-overview-index-mdx" */),
  "component---src-pages-layout-container-mdx": () => import("./../src/pages/layout/Container.mdx" /* webpackChunkName: "component---src-pages-layout-container-mdx" */),
  "component---src-pages-principle-overview-mdx": () => import("./../src/pages/principle/overview.mdx" /* webpackChunkName: "component---src-pages-principle-overview-mdx" */),
  "component---src-pages-principle-philosophy-mdx": () => import("./../src/pages/principle/philosophy.mdx" /* webpackChunkName: "component---src-pages-principle-philosophy-mdx" */),
  "component---src-pages-update-mdx": () => import("./../src/pages/Update.mdx" /* webpackChunkName: "component---src-pages-update-mdx" */),
  "component---src-pages-resources-mdx": () => import("./../src/pages/Resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */)
}

